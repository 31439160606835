<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>报溢报损</span>
            </div>

            <!-- 报损-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToStockLoss"
                            class="font1"
                            :disabled="!hasStockLossPrivilege"
                        >
                            报损
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateStockLoss"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            可新建报损单
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewStockLoss"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核报损单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockStockLoss"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockLoss"
                            :disabled="!hasCreateStockLossPrivilege"
                            >①新建报损单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择机构名称--选择摘要-点击选择商品基本信息-选择需要报损的商品-点击确定-填写报损数量-点击保存
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockLoss"
                            :disabled="!hasReviewStockLossPrivilege"
                        >
                            ②审核报损单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的报损单-点击审核-审核成功后可以出库</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockLoss"
                            :disabled="!hasStockStockLossPrivilege"
                        >
                            ③出库报损商品
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要报损的单据-点击出库-完成后报损的库存会被扣除</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockLoss"
                            :disabled="!hasReviewStockLossPrivilege"
                        >
                            ④报损数量不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现报损数量有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核
                            -确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核报损单操作(已出库的单据不能反审核)
                        </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--报溢-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToStockSurplus"
                            class="font1"
                            :disabled="!hasStockSurplusPrivilege"
                            >报溢</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateStockSurplus"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可新建报溢单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewStockSurplus"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核报溢单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockStockSurplus"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockSurplus"
                            :disabled="!hasCreateStockSurplusPrivilege"
                            >①新建报溢单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择机构名称--选择摘要-点击选择商品基本信息-选择需要报损的商品-点击确定-填写报溢数量-点击保存
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockSurplus"
                            :disabled="!hasReviewStockSurplusPrivilege"
                        >
                            ②审核报溢单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的报溢单-点击审核-审核成功后可以入库</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockSurplus"
                            :disabled="!hasStockStockSurplusPrivilege"
                        >
                            ③入库报溢商品
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要报溢的单据-点击入库-完成后报溢后可进行正常销售</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStockSurplus"
                            :disabled="!hasReviewStockSurplusPrivilege"
                        >
                            ④报溢数量不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现报溢数量有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核
                            -确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核报溢单操作(已入库的单据不能反审核)
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const stockLossPrivilegeFlag = 'menu.stock.loss';
const openStockLossPrivilegeFlag = `${stockLossPrivilegeFlag}.open`;
const createStockLossPrivilegeFlag = `${stockLossPrivilegeFlag}.create`;
const reviewStockLossPrivilegeFlag = `${stockLossPrivilegeFlag}.review`;
const stockStockLossPrivilegeFlag = `${stockLossPrivilegeFlag}.stock`;

const stockSurplusPrivilegeFlag = 'menu.stock.surplus';
const openStockSurplusPrivilegeFlag = `${stockSurplusPrivilegeFlag}.open`;
const createStockSurplusPrivilegeFlag = `${stockSurplusPrivilegeFlag}.create`;
const reviewStockSurplusPrivilegeFlag = `${stockSurplusPrivilegeFlag}.review`;
const stockStockSurplusPrivilegeFlag = `${stockSurplusPrivilegeFlag}.stock`;

export default {
    name: 'StockLossSurplusGuide',
    data() {
        return {
            rolesForCreateStockLoss: [],
            rolesForReviewStockLoss: [],
            rolesForStockStockLoss: [],

            rolesForCreateStockSurplus: [],
            rolesForReviewStockSurplus: [],
            rolesForStockStockSurplus: [],
        };
    },
    created() {
        //查询"能够创建报损单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createStockLossPrivilegeFlag).then((rst) => {
            this.rolesForCreateStockLoss = rst;
        });
        //查询"能够审核报损单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewStockLossPrivilegeFlag).then((rst) => {
            this.rolesForReviewStockLoss = rst;
        });
        //查询"能够出库报损单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockStockLossPrivilegeFlag).then((rst) => {
            this.rolesForStockStockLoss = rst;
        });

        //查询"能够创建报溢单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createStockSurplusPrivilegeFlag).then((rst) => {
            this.rolesForCreateStockSurplus = rst;
        });
        //查询"能够审核报溢单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewStockSurplusPrivilegeFlag).then((rst) => {
            this.rolesForReviewStockSurplus = rst;
        });
        //查询"能够入库报溢单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockStockSurplusPrivilegeFlag).then((rst) => {
            this.rolesForStockStockSurplus = rst;
        });
    },
    computed: {
        roleNamesForCreateStockLoss() {
            return this.rolesForCreateStockLoss.map((e) => e.name).join('，');
        },
        roleNamesForReviewStockLoss() {
            return this.rolesForReviewStockLoss.map((e) => e.name).join('，');
        },
        roleNamesForStockStockLoss() {
            return this.rolesForStockStockLoss.map((e) => e.name).join('，');
        },

        roleNamesForCreateStockSurplus() {
            return this.rolesForCreateStockSurplus.map((e) => e.name).join('，');
        },
        roleNamesForReviewStockSurplus() {
            return this.rolesForReviewStockSurplus.map((e) => e.name).join('，');
        },
        roleNamesForStockStockSurplus() {
            return this.rolesForStockStockSurplus.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToStockLoss() {
            Util.nameJump(this, stockLossPrivilegeFlag);
        },
        jumpToStockSurplus() {
            Util.nameJump(this, stockSurplusPrivilegeFlag);
        },

        hasStockLossPrivilege() {
            return this.hasPrivilege(openStockLossPrivilegeFlag);
        },
        hasCreateStockLossPrivilege() {
            return this.hasPrivilege(createStockLossPrivilegeFlag);
        },
        hasReviewStockLossPrivilege() {
            return this.hasPrivilege(reviewStockLossPrivilegeFlag);
        },
        hasStockStockLossPrivilege() {
            return this.hasPrivilege(stockStockLossPrivilegeFlag);
        },

        hasStockSurplusPrivilege() {
            return this.hasPrivilege(openStockSurplusPrivilegeFlag);
        },
        hasCreateStockSurplusPrivilege() {
            return this.hasPrivilege(createStockSurplusPrivilegeFlag);
        },
        hasReviewStockSurplusPrivilege() {
            return this.hasPrivilege(reviewStockSurplusPrivilegeFlag);
        },
        hasStockStockSurplusPrivilege() {
            return this.hasPrivilege(stockStockSurplusPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
